import Cookies from 'js-cookie'

export const getCookie = (name: string) => Cookies.get(name)

export const hasCookie = (name: string) => Cookies.get(name) !== undefined

export const setCookie = (
    name: string,
    value: string,
    expires?: number,
    path?: string,
    domain?: string
) => {
    Cookies.set(name, value, { expires, path, domain })
}

export const removeCookie = (name: string, path?: string, domain?: string) => {
    Cookies.remove(name, { path, domain })
}
