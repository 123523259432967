import { FC } from 'react'
import Image from 'next/image'
import styled from 'styled-components'
import { Typography } from '@epidemicsound/design-system'
import { smallScreenWidth } from 'hooks/hooks'
const { Title } = Typography

const Container = styled.div`
    width: 90%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 auto;
    justify-content: center;
    align-content: space-around;

    @media only screen and (max-width: ${smallScreenWidth + 'px'}) {
        width: 50%;
    }
`

const LogoContainer = styled.div`
    position: relative;
    width: 7rem;
    height: 7rem;
    margin: 0 3rem;
`

const TrustedTitle = styled(Title)`
    text-transform: uppercase;
    font-size: 0.8rem !important;
    text-align: center;
    color: black !important;
`

export const TrustedBySection: FC = () => {
    return (
        <>
            <TrustedTitle component={'h2'}>Trusted by</TrustedTitle>
            <Container>
                <LogoContainer>
                    <Image
                        src={'/assets/img/partner-logos/squarespace.png'}
                        layout={'fill'}
                        objectFit={'contain'}
                        title={'squarespace'}
                        alt={'squarespace'}
                    />
                </LogoContainer>
                <LogoContainer>
                    <Image
                        src={'/assets/img/partner-logos/unfold.png'}
                        layout={'fill'}
                        objectFit={'contain'}
                        title={'unfold'}
                        alt={'unfold'}
                    />
                </LogoContainer>
                <LogoContainer>
                    <Image
                        src={'/assets/img/partner-logos/storybeat.png'}
                        layout={'fill'}
                        objectFit={'contain'}
                        title={'storybeat'}
                        alt={'storybeat'}
                    />
                </LogoContainer>
                <LogoContainer>
                    <Image
                        src={'/assets/img/partner-logos/d.png'}
                        layout={'fill'}
                        objectFit={'contain'}
                        title={'line'}
                        alt={'storybeat'}
                    />
                </LogoContainer>
                <LogoContainer>
                    <Image
                        src={'/assets/img/partner-logos/inshot.png'}
                        layout={'fill'}
                        objectFit={'contain'}
                        title={'lightworks'}
                        alt={'lightworks'}
                    />
                </LogoContainer>
                <LogoContainer>
                    <Image
                        src={'/assets/img/partner-logos/socialkit.png'}
                        layout={'fill'}
                        objectFit={'contain'}
                        title={'socialkit'}
                        alt={'socialkit'}
                    />
                </LogoContainer>
                <LogoContainer>
                    <Image
                        src={'/assets/img/partner-logos/technogym.png'}
                        layout={'fill'}
                        objectFit={'contain'}
                        title={'dizzi'}
                        alt={'dizzi'}
                    />
                </LogoContainer>
                <LogoContainer>
                    <Image
                        src={'/assets/img/partner-logos/lightworks.png'}
                        layout={'fill'}
                        objectFit={'contain'}
                        title={'lightricks'}
                        alt={'lightricks'}
                    />
                </LogoContainer>
                <LogoContainer>
                    <Image
                        src={'/assets/img/partner-logos/tezza.png'}
                        layout={'fill'}
                        objectFit={'contain'}
                        title={'bazaart'}
                        alt={'bazaart'}
                    />
                </LogoContainer>
            </Container>
        </>
    )
}
