import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import React, { ReactNode } from 'react'
import useEmblaCarousel from 'embla-carousel-react'

import { smallScreenWidth } from 'hooks/hooks'
import { EmblaOptionsType } from 'embla-carousel'

const EmblaContainer = styled.div`
    display: flex;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -webkit-tap-highlight-color: transparent;
`

const EmblaViewPort = styled.div`
    overflow: hidden;
    width: 100%;
`

const Embla = styled.div`
    position: relative;
    max-width: 100%;
`

const EmblaSlide = styled.div`
    position: relative;
`

const CarouselButton = styled.button`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    outline: 0;
    cursor: pointer;
    touch-action: manipulation;
    border: 0;
    justify-content: center;
    align-items: center;
    color: white;
    width: 8rem;
    height: 100%;
    fill: white;
    background: rgb(238, 238, 238);

    &:disabled {
        visibility: hidden;
    }

    @media only screen and (max-width: ${smallScreenWidth + 'px'}) {
        width: 4rem;
    }
`

const ButtonLeft = styled(CarouselButton)`
    left: 0rem;
    background: linear-gradient(
        -90deg,
        rgba(238, 238, 238, 0) 0%,
        rgba(0, 0, 0, 0.7) 100%
    );
`

const ButtonRight = styled(CarouselButton)`
    right: 0rem;
    background: linear-gradient(
        90deg,
        rgba(238, 238, 238, 0) 0%,
        rgba(0, 0, 0, 0.7) 100%
    );
`

const StyledSvg = styled.svg`
    width: 1.5rem;
    height: 1.5rem;

    @media only screen and (max-width: ${smallScreenWidth + 'px'}) {
        width: 3rem;
        height: 3rem;
    }
`

type PropType = {
    options?: EmblaOptionsType
    slides: ReactNode[]
}

const Carousel = (props: PropType) => {
    const { slides, options } = props
    const [emblaRef, emblaApi] = useEmblaCarousel(options)
    const [prevBtnEnabled, setPrevBtnEnabled] = useState(false)
    const [nextBtnEnabled, setNextBtnEnabled] = useState(false)

    const onSelect = useCallback(() => {
        if (!emblaApi) return
        setPrevBtnEnabled(emblaApi.canScrollPrev())
        setNextBtnEnabled(emblaApi.canScrollNext())
    }, [emblaApi])

    useEffect(() => {
        if (!emblaApi) return
        emblaApi.on('select', onSelect)
        onSelect()
    }, [emblaApi, onSelect])

    const scrollPrev = useCallback(() => {
        if (emblaApi) emblaApi.scrollPrev()
    }, [emblaApi])

    const scrollNext = useCallback(() => {
        if (emblaApi) emblaApi.scrollNext()
    }, [emblaApi])

    return (
        <Embla>
            <EmblaViewPort ref={emblaRef}>
                <EmblaContainer>
                    {slides.map((slide, index) => (
                        <EmblaSlide key={index}>{slide}</EmblaSlide>
                    ))}
                </EmblaContainer>
            </EmblaViewPort>

            <ButtonLeft
                onClick={scrollPrev}
                disabled={!prevBtnEnabled}
                aria-label={'Scroll left'}
            >
                <StyledSvg viewBox="137.718 -1.001 366.563 644">
                    <path d="M428.36 12.5c16.67-16.67 43.76-16.67 60.42 0 16.67 16.67 16.67 43.76 0 60.42L241.7 320c148.25 148.24 230.61 230.6 247.08 247.08 16.67 16.66 16.67 43.75 0 60.42-16.67 16.66-43.76 16.67-60.42 0-27.72-27.71-249.45-249.37-277.16-277.08a42.308 42.308 0 0 1-12.48-30.34c0-11.1 4.1-22.05 12.48-30.42C206.63 234.23 400.64 40.21 428.36 12.5z" />
                </StyledSvg>
            </ButtonLeft>

            <ButtonRight
                onClick={scrollNext}
                disabled={!nextBtnEnabled}
                aria-label={'Scroll right'}
            >
                <StyledSvg viewBox="0 0 238.003 238.003">
                    <path d="M181.776 107.719L78.705 4.648c-6.198-6.198-16.273-6.198-22.47 0s-6.198 16.273 0 22.47l91.883 91.883-91.883 91.883c-6.198 6.198-6.198 16.273 0 22.47s16.273 6.198 22.47 0l103.071-103.039a15.741 15.741 0 0 0 4.64-11.283c0-4.13-1.526-8.199-4.64-11.313z" />
                </StyledSvg>
            </ButtonRight>
        </Embla>
    )
}

export default Carousel
