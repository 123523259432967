import { Button } from '@epidemicsound/design-system/v2'
import styled from 'styled-components'

export const BlackButton = styled(Button)`
    background-color: black !important;
    color: white !important;
    border: 1px solid black;
`

export const GreyButton = styled(Button)`
    background-color: rgba(0, 0, 0, 0.06) !important;
    color: black !important;
    border: 1px solid black;
    text-decoration: none !important;
`
