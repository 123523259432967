import React, { FC, useEffect } from 'react'
import { Navigation } from 'components/navigation/landingpage/Navigation'
import { ThemeProvider, THEMES } from '@epidemicsound/design-system'
import LoadingProvider from 'providers/loadingprovider'
import ActiveTrackProvider from 'providers/activetrackprovider'
import PlayerQueueProvider from 'providers/playerqueueprovider'
import EnvironmentProvider from 'providers/environmentprovider'
import { PlayerBar } from 'components/audio/PlayerBar'
import ContentfulPreviewProvider from 'providers/contentfulpreviewprovider'
import { MetaHead } from 'components/metadata/MetaTags'
import { pageView } from 'utils/tracking'
import { CookieConsent } from 'components/cookieconsent/CookieConsent'

export const LandingPageLayout: FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    useEffect(() => {
        pageView('Landingpage')
    }, [])

    const tabs = [
        {
            id: '1',
            label: 'Documentation',
            path: 'https://developers.epidemicsound.com/docs',
            isExternal: true,
        },
        {
            id: '2',
            label: 'Log in as Partner',
            path: '/portal/callback/',
            color: '#009DE0',
        },
    ]

    return (
        <>
            <ThemeProvider theme={THEMES.DARK}>
                <ContentfulPreviewProvider initialPreview={false}>
                    <EnvironmentProvider>
                        <ActiveTrackProvider>
                            <PlayerQueueProvider>
                                <LoadingProvider initialLoading={false}>
                                    <MetaHead />
                                    <Navigation
                                        title={'Developers'}
                                        tabs={tabs}
                                        theme="light"
                                    />
                                    {children}
                                    <PlayerBar app={'Landingpage'} />
                                    <CookieConsent></CookieConsent>
                                </LoadingProvider>
                            </PlayerQueueProvider>
                        </ActiveTrackProvider>
                    </EnvironmentProvider>
                </ContentfulPreviewProvider>
            </ThemeProvider>
        </>
    )
}

export default LandingPageLayout
