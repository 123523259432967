import styled from 'styled-components'
import { Typography } from '@epidemicsound/design-system'

const { Text } = Typography

export const TextRebrandHeader = styled(Text)`
    font-family: var(--es-header-font-family) !important;
    color: black !important;
    line-height: 3rem !important;
`

export const TextRebrand = styled(Text)`
    font-family: var(--es-text-font-family) !important;
    color: black !important;
`
