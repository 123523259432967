import Head from 'next/head'
import { FC } from 'react'

interface MetaTagsProps {
    title?: string
    description?: string
}

export const MetaHead: FC<MetaTagsProps> = ({ title, description }) => {
    const metaTitle = title || 'Developers | Epidemic Sound Connect'
    const metaDescription =
        description ||
        'Integrate our API into your app with Epidemic Sound Connect and get access to all of our curated music collections which are updated regularly by our experts.'
    const imgUrl =
        'https://cdn.epidemicsound.com/legacy/19/images/epidemic-sound-logo-square.png'
    const domain = 'developers.epidemicsound.com'

    return (
        <>
            <Head>
                {/*HTML Meta Tags */}
                <title>{metaTitle}</title>
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1"
                />
                <meta name="description" content={metaDescription} />

                {/*Facebook Meta Tags*/}
                <meta property="og:url" content={`https://${domain}`} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={metaTitle} key="title" />
                <meta property="og:description" content={metaDescription} />
                <meta property="og:image" content={imgUrl} />

                {/*Twitter Meta Tags*/}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@epidemicsound" />
                <meta property="twitter:domain" content={domain} />
                <meta property="twitter:url" content={`https://${domain}`} />
                <meta name="twitter:title" content={metaTitle} />
                <meta name="twitter:description" content={metaDescription} />
                <meta name="twitter:image" content={imgUrl} />
                <meta
                    name="google-site-verification"
                    content="uaLT0v8-9yjP6pGz4ti7ttlAa15P2rKLc6jyCF4Ok3w"
                />
            </Head>
        </>
    )
}
