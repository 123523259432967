import Script from 'next/script'
import { CookieConsentBanner } from './CookieConsentBanner'
import { showBannerScript } from './showBannerScript'

const TEXTS = {
    cookie_banner_policy_title: 'We use cookies',
    cookie_banner_policy_text:
        'We and our partners use cookies to store and access personal data such as browsing data for purposes such as serving and personalizing content and advertising and analyzing site traffic. You may find out more about the purposes for which we and our partners use cookies or exercise your preferences by clicking the "Change cookie settings" button below.',
    cookie_banner_button_accept: 'Accept',
    cookie_banner_button_settings: 'Change cookie settings',
}

function CookieConsent() {
    return (
        <>
            <Script
                src="https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js"
                type="text/javascript"
                data-document-language="true"
                strategy="lazyOnload"
                data-domain-script={
                    process.env.NEXT_PUBLIC_COOKIE_PRO_SCRIPT_ID
                }
            />
            <CookieConsentBanner
                policyTitle={TEXTS.cookie_banner_policy_title}
                policyText={TEXTS.cookie_banner_policy_text}
                acceptButtonCtaText={TEXTS.cookie_banner_button_accept}
                settingsButtonCtaText={TEXTS.cookie_banner_button_settings}
            />
            {/* Intentionally native lowercase script tag, as this will be directly loaded into the body at specific order */}
            <script
                id="show-es-cookie-banner"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                    __html: showBannerScript,
                }}
            />
        </>
    )
}

export { CookieConsent }
