/* eslint-disable prefer-template */
/* eslint-disable func-names */

// Please note that the script is render-blocking so should keep it short.
// Also, it will not be compiled by Babel so avoid using new JS features.

const code = function () {
    function showCookieBanner() {
        const esConsentBanner = document.getElementById('es-cookie-banner')
        if (esConsentBanner) {
            esConsentBanner.style.display = 'block'
        }
    }

    function hasCookie(name) {
        return document.cookie.indexOf(name + '=') !== -1
    }

    function hasSeenCookieDisclosure() {
        return hasCookie('hasSeenCookieDisclosure')
    }

    function hasCookieProCookie() {
        return hasCookie('OptanonAlertBoxClosed')
    }

    if (!hasCookieProCookie() && !hasSeenCookieDisclosure()) {
        showCookieBanner()
    }
}

export const showBannerScript = `(${code})();`
