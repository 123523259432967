import styled from 'styled-components'
import { Icon } from '@epidemicsound/design-system'
import { PlaybackStatus } from '../../utils'
import { IconType, StyledIcon } from '../icon/Icon'
import { FC } from 'react'

const StyledPlayIcon = styled(StyledIcon)`
    opacity: 1;
    margin-left: 12px;
    position: absolute;
`

interface PlayIconProps {
    status: PlaybackStatus
    onClick?: () => void
    isLoading: boolean
}

export const PlayIcon: FC<PlayIconProps> = ({
    status,
    onClick,
    isLoading,
    ...props
}) => {
    const currentIcon = status === 'play' ? <Icon.Pause /> : <Icon.Play />

    return (
        <StyledPlayIcon onClick={() => onClick?.()} {...props}>
            <IconType isLoading={isLoading} icon={currentIcon} />
        </StyledPlayIcon>
    )
}
