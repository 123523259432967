import { hasCookie, setCookie, removeCookie } from '../../utils/cookie'
import { trackButtonClick } from '../../utils/tracking'

export const COOKIE_PRO_COOKIE_NAME = 'OptanonAlertBoxClosed'
const HAS_SEEN_COOKIE_DISCLOSURE_COOKIE_NAME = 'hasSeenCookieDisclosure'
const COOKIE_CONSENT_UPDATE_EVENT_TYPE = 'COOKIE_CONSENT_UPDATE_EVENT'

const openSettings = () => {
    if (!window.OneTrust) return

    window.OneTrust.ToggleInfoDisplay()
}

const dispatchHasSeenCookieEvent = () => {
    const cookieEvent = new Event(COOKIE_CONSENT_UPDATE_EVENT_TYPE)
    document.dispatchEvent(cookieEvent)
}

const setHasSeenCookieDisclosure = () => {
    setCookie(HAS_SEEN_COOKIE_DISCLOSURE_COOKIE_NAME, 'true', 30)
}

const getHasSeenCookieDisclosure = () =>
    hasCookie(HAS_SEEN_COOKIE_DISCLOSURE_COOKIE_NAME)

const setCookieProCookie = () => {
    if (!window.OneTrust) return

    window.OneTrust.AllowAll()
    window.OneTrust.TriggerGoogleAnalyticsEvent(
        'OneTrust Cookie Consent',
        'Banner Accept Cookies'
    )
}

const cookieProIsClosedAndValid = () => {
    if (!window.OneTrust) return false

    return window.OneTrust.IsAlertBoxClosedAndValid()
}

const trackCookieConsentChanged = () => {
    if (!window.OnetrustActiveGroups) return

    trackButtonClick(window.OnetrustActiveGroups, 'COOKIE_CONSENT_BANNER')
}

const deleteNoConsentCookies = () => {
    const domainGroups = window.Optanon?.GetDomainData().Groups
    const activeGroups = window.OnetrustActiveGroups?.split(',').filter(Boolean)

    if (!domainGroups || !activeGroups) return

    domainGroups
        .filter(({ CustomGroupId }) => !activeGroups.includes(CustomGroupId))
        .flatMap(({ Cookies }) => Cookies)
        .forEach(({ Name }) => removeCookie(Name))
}

export {
    openSettings,
    dispatchHasSeenCookieEvent,
    getHasSeenCookieDisclosure,
    setHasSeenCookieDisclosure,
    setCookieProCookie,
    cookieProIsClosedAndValid,
    trackCookieConsentChanged,
    deleteNoConsentCookies,
}
