'use client'

import { useCallback, useEffect, useState } from 'react'
import { Button } from '@epidemicsound/design-system/v2'
import { Typography } from '@epidemicsound/design-system'
import styled from 'styled-components'
import {
    cookieProIsClosedAndValid,
    deleteNoConsentCookies,
    dispatchHasSeenCookieEvent,
    getHasSeenCookieDisclosure,
    openSettings,
    setCookieProCookie,
    setHasSeenCookieDisclosure,
    trackCookieConsentChanged,
} from './settings'

const CookieConsentWrapper = styled.div`
    z-index: 2147483647;
    position: fixed;
    left: 0;
    right: 0;
    top: 10%;
    display: flex;
    justify-content: center;
    // TODO: import from latest release of design system v2
    // padding: 0 var(--es-grid-padding-small);
    padding: 0;
`

const CookieConsent = styled.div`
    box-sizing: border-box;
    padding: 2rem;
    max-width: 40.625rem; /* 650px */
    // TODO: import from latest release of design system v2
    // background-color: var(--es-color-background-elevation);
    background-color: rgb(32, 32, 32);
`

const PolicyTitle = styled(Typography.Text)`
    margin: 0;
`
const PolicyText = styled(Typography.Text)`
    margin: 2rem 0;
`

const ButtonGroup = styled.div`
    display: flex;
    flex-wrap: wrap-reverse;
    gap: 1rem;
    justify-content: flex-end;

    > * {
        flex-grow: 1;
    }
`

const Overlay = styled.div`
    z-index: 2147483646;
    // TODO: import from latest release of design system v2
    // background: var(--es-color-background-backdrop);
    background: rgba(0, 0, 0, 0.75);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
`

interface CookieConsentBannerProps {
    policyTitle: string
    policyText: string
    acceptButtonCtaText: string
    settingsButtonCtaText: string
}

function CookieConsentBanner({
    policyTitle,
    policyText,
    acceptButtonCtaText,
    settingsButtonCtaText,
}: CookieConsentBannerProps) {
    const [showCookieBanner, setShowCookieBanner] = useState(true)

    const hideCookieBanner = useCallback(() => {
        setShowCookieBanner(false)
        dispatchHasSeenCookieEvent()
    }, [])

    const handleOnConsentChanged = useCallback(() => {
        trackCookieConsentChanged()
        deleteNoConsentCookies()

        if (cookieProIsClosedAndValid()) {
            hideCookieBanner()
        }
    }, [hideCookieBanner])

    useEffect(() => {
        window.OptanonWrapper = () => {
            window.OneTrust?.OnConsentChanged(handleOnConsentChanged)
            if (!cookieProIsClosedAndValid() && getHasSeenCookieDisclosure()) {
                setCookieProCookie()
            }
        }
        return () => {
            window.OptanonWrapper = undefined
        }
    }, [handleOnConsentChanged])

    const handleOnClickAccept = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.preventDefault()

        hideCookieBanner()
        setHasSeenCookieDisclosure()

        if (!cookieProIsClosedAndValid()) {
            setCookieProCookie()
        }
    }

    const handleOnClickSettings = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.preventDefault()

        if (!cookieProIsClosedAndValid()) {
            openSettings()
        }
    }

    if (!showCookieBanner) return null

    return (
        <div
            id="es-cookie-banner"
            style={{ display: 'none' }}
            suppressHydrationWarning
        >
            <CookieConsentWrapper>
                <CookieConsent>
                    <PolicyTitle component="h3" size={Typography.Text.SIZES.XL}>
                        {policyTitle}
                    </PolicyTitle>
                    <PolicyText
                        component="p"
                        size={Typography.Text.SIZES.S_XS}
                        color="secondary"
                    >
                        {policyText}
                    </PolicyText>
                    <ButtonGroup>
                        <Button
                            onClick={handleOnClickSettings}
                            variant="secondary"
                        >
                            {settingsButtonCtaText}
                        </Button>
                        <Button
                            id="es-consent-accept-btn"
                            onClick={handleOnClickAccept}
                            variant="primary"
                        >
                            {acceptButtonCtaText}
                        </Button>
                    </ButtonGroup>
                </CookieConsent>
            </CookieConsentWrapper>
            <Overlay />
        </div>
    )
}

export { CookieConsentBanner }
