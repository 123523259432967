import Image from 'next/image'
import styled from 'styled-components'

const LogoContainer = styled.div`
    position: relative;
    width: 15rem;
    height: 2.25rem;

    @media only screen and (max-width: 768px) {
        width: 10rem;
        height: 1.5rem;
    }
`

export const EpidemicLogo = () => (
    <LogoContainer>
        <Image
            src="/assets/img/Epidemic2024.png"
            layout="fill"
            alt="Epidemic Sound Logo"
        />
    </LogoContainer>
)
