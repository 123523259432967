import { FC, ReactNode } from 'react'
import styled from 'styled-components'
import { constants, Spinner } from '@epidemicsound/design-system'

const { COLORS } = constants

export const StyledIcon = styled.i`
    z-index: 500;
    cursor: pointer;

    & svg {
        fill: ${COLORS.WHITE};
    }

    & svg:focus,
    & svg:hover {
        fill: ${COLORS.GRAY_2};
    }
`

interface IconTypeProps {
    isLoading: boolean
    icon: ReactNode
}

export const IconType: FC<IconTypeProps> = ({ isLoading, icon }) => {
    if (isLoading) {
        icon = <Spinner size={24} />
    }

    return <>{icon}</>
}
