import { BlackButton, GreyButton } from 'components/button/Buttons'
import { TextRebrand, TextRebrandHeader } from 'components/text/TextRebrand'
import { smallScreenWidth, useLanding } from 'hooks/hooks'
import Image from 'next/image'
import { FC } from 'react'
import styled from 'styled-components'
import { API_FORM_REQUEST_URL } from 'utils'
import { trackButtonClick } from 'utils/tracking'

const StyledIntroductionSection = styled.section`
    display: flex;
    padding: 10rem 50vw 10rem 100px;
    @media only screen and (max-width: ${smallScreenWidth + 'px'}) {
        padding: 1rem;
        padding-top: 4rem;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding-bottom: 60vw;
    }

    @media only screen and (min-width: 1920px) {
        padding-right: 50vw;
        padding-left: 22vw;
    }

    position: relative;

    background-color: var(--es-background-brand-fog);
`

const StyledTitle = styled(TextRebrandHeader)`
    font-size: 3rem !important;
    line-height: 3rem !important;
`

const StyledButtons = styled.div`
    margin-top: 1.5rem;
    width: 90%;
    display: flex;
    flex-direction: row;

    button {
        display: flex;
        flex-direction: column;
        width: 50%;
        align-items: center;
    }

    button:not(:last-child) {
        margin-right: 1rem;
    }

    @media only screen and (max-width: ${smallScreenWidth + 'px'}) {
        width: 60%;
        display: block;
        flex-direction: unset;
        margin: 4rem auto;

        button:not(:last-child) {
            margin-right: 0;
        }

        button {
            width: 100%;
            margin-bottom: 0.5rem;
            display: block;
            flex-direction: unset;
        }
    }
`

const StyledImageContainer = styled.div`
    @media only screen and (min-width: 1920px) {
        min-width: 600px;
        width: 34vw;
    }

    @media only screen and (max-width: ${smallScreenWidth + 'px'}) {
        width: 100vw;
    }
    position: absolute;
    width: 50vw;
    right: 0;
    bottom: -4px;
`

const StyledContentLeft = styled.div`
    @media only screen and (max-width: ${smallScreenWidth + 'px'}) {
        margin-right: 0rem;
    }
`

export type Props = {
    video: string
    width: string
    height: string
    thumbnailQuality: 'default' | 'hqdefault' | 'mqdefault' | 'sddefault'
}

export const IntroductionSection: FC = () => {
    const openContactSales = () => {
        trackButtonClick('contact-sales-button-clicked')
        window.open(API_FORM_REQUEST_URL)
    }

    const [push] = useLanding()
    const openDocumentation = () => {
        trackButtonClick('documentation-button-clicked')
        push('/docs')
    }

    return (
        <StyledIntroductionSection data-cy-landing-page-intro-section>
            <StyledContentLeft>
                <StyledTitle color={'black'} component={'p'}>
                    Supercharge your in-app music experience
                </StyledTitle>

                <TextRebrand component="div">
                    Give your users full access to the Epidemic Sound catalog,
                    including industry leading soundtracking tools, through the
                    Epidemic Sound Partner API. Users can unlock unlimited usage
                    of more than 40,000 original tracks across 160 genres and
                    90,000 sound effects, all fully cleared for commercial use
                    across platforms.
                </TextRebrand>
                <StyledButtons>
                    <BlackButton
                        onClick={openContactSales}
                        backgroundColor={'black'}
                    >
                        Contact Sales
                    </BlackButton>
                    <GreyButton variant="secondary" onClick={openDocumentation}>
                        Documentation
                    </GreyButton>
                </StyledButtons>
            </StyledContentLeft>
            <StyledImageContainer>
                <Image
                    src="/assets/img/hands-epidemic.png"
                    width={887}
                    height={700}
                    alt="Landing page hero image"
                />
            </StyledImageContainer>
        </StyledIntroductionSection>
    )
}
