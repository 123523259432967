import { PublicCollectionResponse } from 'api/responses'
import { BlackButton, GreyButton } from 'components/button/Buttons'
import Carousel from 'components/carousel/Carousel'
import { TextRebrandHeader } from 'components/text/TextRebrand'
import { smallScreenWidth, useLanding } from 'hooks/hooks'
import { FC } from 'react'
import styled from 'styled-components'
import { API_FORM_REQUEST_URL } from 'utils'
import { trackButtonClick } from 'utils/tracking'
import { SectionContainer } from '../styles'
import { Cover } from './collectionSection/Cover'

const CarouselContainer = styled(SectionContainer)`
    padding: 10rem 0;
    background-color: black;

    @media only screen and (max-width: ${smallScreenWidth + 'px'}) {
        padding: 5rem 0;
    }
`

const CarouselItem = styled.div`
    display: flex;
    padding: 0 8px;
`

const TextContainer = styled.div`
    padding: 5rem 22vw;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: ${smallScreenWidth + 'px'}) {
        padding: 5rem 0;
    }
`

const StyledTitle = styled(TextRebrandHeader)`
    font-size: 48px !important;
    line-height: 4rem !important;
    max-width: 1000px;
`

const StyledButtons = styled.div`
    margin-top: 1.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
`

interface Props {
    collections: PublicCollectionResponse[]
}

export const WorryFreeSection: FC<Props> = ({ collections }) => {
    const openContactSales = () => {
        trackButtonClick('contact-sales-button-clicked')
        window.open(API_FORM_REQUEST_URL)
    }
    const slides = collections.map((i) => {
        return (
            <CarouselItem data-cy-landing-page-collection-cover key={i.title}>
                <Cover collection={i} />
            </CarouselItem>
        )
    })

    const [push] = useLanding()
    const openDocumentation = () => {
        trackButtonClick('documentation-button-clicked')
        push('/docs')
    }
    return (
        <div>
            <CarouselContainer data-cy-landing-page-worry-free-section>
                <Carousel
                    slides={slides}
                    options={{ startIndex: 5, containScroll: 'trimSnaps' }}
                />
            </CarouselContainer>
            <TextContainer>
                <StyledTitle color={'black'} component={'h2'}>
                    Take your app to the next level with the Epidemic Sound
                    Partner API
                </StyledTitle>
                <StyledButtons>
                    <BlackButton
                        onClick={openContactSales}
                        backgroundColor={'black'}
                    >
                        Contact Sales
                    </BlackButton>
                    <GreyButton variant="secondary" onClick={openDocumentation}>
                        Documentation
                    </GreyButton>
                </StyledButtons>
            </TextContainer>
        </div>
    )
}
