import { FC, useState } from 'react'
import { smallScreenWidth } from 'hooks/hooks'
import {
    constants,
    FullPageGrid,
    Typography,
    Icon,
} from '@epidemicsound/design-system'
import { ButtonLink } from '@epidemicsound/design-system/v2'
import Link from 'next/link'
import styled from 'styled-components'
import { signIn } from 'next-auth/react'
import { BlackButton, GreyButton } from 'components/button/Buttons'
import { EpidemicLogo } from 'components/logo/EpidemicLogo'

const { Text } = Typography

export const StyledNavigation = styled.nav<{ theme: 'light' | 'dark' }>`
    position: fixed;
    width: 100%;
    top: 0;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    display: flex;
    flex-direction: row;
    z-index: 10;
    background-color: ${(props) => {
        return props.theme === 'light'
            ? 'var(--es-background-brand-off-white)'
            : constants.COLORS.BLACK
    }};
`

const StyledButtonLink = styled(ButtonLink)`
    color: black !important;
`

const StyledText = styled(Text)`
    font-size: 1rem !important;
    color: black !important;
`

export const StyledCol = styled(FullPageGrid.Col)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

const StyledLeftContent = styled.div`
    display: flex;
    align-items: center;
`

const StyledLogo = styled.div`
    border-right: 1px solid black;
    padding-right: 1rem;
    margin-right: 1rem;
    cursor: pointer;
    color: black !important;
`

const StyledRightContent = styled.div`
    display: flex;
    align-items: space-between;
    gap: 2rem;
    justify-content: flex-end;
`

const StyledLinks = styled.div`
    display: flex;
    justify-content: flex-start;
    margin-top: 1.5rem;
    @media only screen and (max-width: ${smallScreenWidth + 'px'}) {
        flex-direction: column;
        align-items: revert;
    }
`

const StyledTab = styled.div`
    padding-left: 1rem;
    @media only screen and (max-width: ${smallScreenWidth + 'px'}) {
        padding-left: 0.5rem;
        margin-bottom: 1.5rem;
        margin-top: 0.5rem;
    }
`

const StyledMobile = styled.div`
    display: none;
    @media only screen and (max-width: ${smallScreenWidth + 'px'}) {
        display: flex;
        align-items: center;
    }
`

const StyledBrowser = styled.div`
    display: flex;
    @media only screen and (max-width: ${smallScreenWidth + 'px'}) {
        display: none;
    }
`

const CustomGrid = styled(FullPageGrid)`
    max-width: 1780px !important;
`

const StyledATag = styled.a`
    text-decoration: none;
    color: black;
`

const StyledGreyButton = styled(GreyButton)`
    margin-right: 1rem;
`

export interface CustomTabListItemProps {
    isExternal?: boolean
    id: string
    label: string
    path: string
    color?: string
}

interface NavigationProps {
    title: string
    tabs: CustomTabListItemProps[]
    theme?: 'dark' | 'light'
}

const getTabItem = (tab: CustomTabListItemProps) => (
    <StyledTab key={tab.id}>
        {tab.isExternal ? (
            <StyledButtonLink
                variant="secondary"
                data-cy-navigation-link
                href={tab.path}
            >
                {tab.label}
            </StyledButtonLink>
        ) : (
            <StyledButtonLink
                variant="secondary"
                data-cy-navigation-link
                onClick={() =>
                    signIn('keycloak', { callbackUrl: '/portal/callback/' })
                }
            >
                {tab.label}
            </StyledButtonLink>
        )}
    </StyledTab>
)

export const Navigation: FC<NavigationProps> = ({
    title,
    tabs,
    theme = 'dark',
}) => {
    const [isOpen, setIsOpen] = useState(false)

    return (
        <StyledNavigation theme={theme}>
            <CustomGrid>
                <StyledCol size={12}>
                    <StyledLeftContent>
                        <Link href="/" passHref>
                            <StyledLogo>
                                <EpidemicLogo />
                            </StyledLogo>
                        </Link>
                        <StyledText>{title}</StyledText>
                    </StyledLeftContent>

                    <StyledRightContent>
                        <StyledBrowser>
                            <StyledGreyButton>
                                <StyledATag href="/docs">
                                    Documentation
                                </StyledATag>
                            </StyledGreyButton>
                            <BlackButton
                                variant="secondary"
                                data-cy-navigation-link
                                onClick={() =>
                                    signIn('keycloak', {
                                        callbackUrl: '/portal/callback/',
                                    })
                                }
                            >
                                Log in as partner
                            </BlackButton>
                        </StyledBrowser>
                        <StyledMobile>
                            {!isOpen ? (
                                <Icon.Menu
                                    isVisible={isOpen}
                                    onClick={() => setIsOpen(true)}
                                    fill={'black'}
                                />
                            ) : (
                                <>
                                    <Icon.Close
                                        onClick={() => setIsOpen(false)}
                                        fill={'black'}
                                    />
                                </>
                            )}
                        </StyledMobile>
                    </StyledRightContent>
                </StyledCol>
                {isOpen ? (
                    <StyledCol size={12}>
                        <StyledMobile>
                            <StyledLinks>
                                {tabs.map((tab) => getTabItem(tab))}
                            </StyledLinks>
                        </StyledMobile>
                    </StyledCol>
                ) : (
                    <></>
                )}
            </CustomGrid>
        </StyledNavigation>
    )
}
