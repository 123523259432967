import { FC } from 'react'
import Image from 'next/image'
import styled from 'styled-components'
import { SectionContainer } from '../styles'
import { smallScreenWidth } from 'hooks/hooks'
import { TextRebrand, TextRebrandHeader } from 'components/text/TextRebrand'

const MainContainer = styled(SectionContainer)`
    padding-top: 3rem;

    @media only screen and (max-width: ${smallScreenWidth + 'px'}) {
        text-align: center;
    }
`

const Title = styled(TextRebrandHeader)`
    font-size: 48px !important;
    line-height: 54px !important;
    max-width: 1000px;
    margin-bottom: 1rem;
`

const UpperContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    @media only screen and (max-width: ${smallScreenWidth + 'px'}) {
        padding: 0 1rem;
    }
`

const SubTitle = styled(TextRebrandHeader)`
    font-size: 32px !important;
    line-height: 54px !important;
`

const Text = styled(TextRebrand)`
    max-width: 54rem;
    margin-bottom: 3.625rem;
    margin-top: 1.5rem;
`

const TextContainer = styled.div`
    max-width: 54rem;
    margin-bottom: 3.625rem;
    margin-top: 1.5rem;
`

const FlexContainer = styled.div`
    display: flex;
    width: 80%;
    justify-content: center;
    gap: 5rem;

    @media only screen and (max-width: ${smallScreenWidth + 'px'}) {
        flex-direction: column;
        gap: 0;
    }
`

const DesktopImage = styled.div`
    display: block;
`

export const UnlockSection: FC = () => {
    return (
        <MainContainer data-cy-landing-page-unlock-section>
            <UpperContainer>
                <Title color={'black'} component={'h2'}>
                    Seamless access to the leading catalog for creators
                </Title>
                <TextContainer>
                    <Text color={'black'}>
                        70% of the top 200 YouTube channels are soundtracked
                        with our music for good reason. We have high-quality
                        music for every occasion, featuring an array of globally
                        acclaimed producers and emerging artists.
                    </Text>
                </TextContainer>
            </UpperContainer>
            <FlexContainer>
                <div>
                    <SubTitle color={'black'}>Music </SubTitle>
                    <TextContainer>
                        <Text color={'black'}>
                            Empower your users with a catalog of over 40,000
                            original tracks, with all rights included. Our music
                            is delivered through playlists representing moods,
                            themes, and genres, and are regularly updated by our
                            team of in-house music experts.
                        </Text>
                    </TextContainer>
                </div>
                <div>
                    <SubTitle color={'black'}>Sound effects </SubTitle>
                    <TextContainer>
                        <Text color={'black'}>
                            Whoosh, cartoon or ghost sounds. Discover our
                            catalog of over 90,000 SFX - with over 300 different
                            categories, your users are sure to find what they
                            are looking for.
                        </Text>
                    </TextContainer>
                </div>
            </FlexContainer>
            <FlexContainer>
                <DesktopImage>
                    <Image
                        src={'/assets/img/genres.png'}
                        height={1100}
                        width={2600}
                        alt="Collections"
                    />
                </DesktopImage>
            </FlexContainer>
        </MainContainer>
    )
}
