import {
    PageViewEvent,
    CommonEventProperties,
    newTracker,
    trackPageView,
    trackSelfDescribingEvent,
} from '@snowplow/browser-tracker'

import * as snowPlow from './snowplow'

// Instantiate a new Tracker Object (needed only once)
newTracker(
    snowPlow.const.SNOWPLOW_TRACKER_ID,
    snowPlow.const.SNOWPLOW_COLLECTER_URL,
    {
        appId: snowPlow.const.SNOWPLOW_APP_ID,
        postPath: snowPlow.const.SNOWPLOW_POST_PATH,
        discoverRootDomain: true,
        cookieSameSite: 'Lax',
        // If DNT header is present and enabled, tracker will not send tracking requests
        respectDoNotTrack: true,
        contexts: {
            webPage: true,
            session: true,
        },
    }
)

const pageView = (title?: string) => {
    const pageViewEvent: PageViewEvent & CommonEventProperties = {
        title: title,

        context: [
            snowPlow.pageViewSchemaData(window.location.pathname),
            snowPlow.addEventUUIDEntity(),
            snowPlow.addWebSessionEntity(),
        ],
    }

    trackPageView(pageViewEvent)
}

const trackButtonClick = (action?: string, context?: string) => {
    trackSelfDescribingEvent(
        {
            event: {
                schema: snowPlow.schemas.CTA_CLICKED,
                data: {
                    action: action,
                },
            },
            context: [
                snowPlow.addEventUUIDEntity(),
                snowPlow.addWebSessionEntity(),
                snowPlow.addContextEntity(context),
            ],
        },
        [
            snowPlow.const.DEVELOPERS_PORTAL_TRACKER_NAME,
            snowPlow.const.DEVELOPERS_PORTAL_CTX,
        ]
    )
}

export { trackButtonClick, pageView }
