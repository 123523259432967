import { FC } from 'react'
import styled from 'styled-components'
import Image from 'next/image'
import { smallScreenWidth } from 'hooks/hooks'
import { SectionContainer } from '../styles'
import { TextRebrand, TextRebrandHeader } from 'components/text/TextRebrand'
import { Icon } from '@epidemicsound/design-system'

const MainContainer = styled(SectionContainer)`
    padding-top: 2rem;
    padding-bottom: 8rem;

    @media only screen and (max-width: ${smallScreenWidth + 'px'}) {
        align-items: flex-start;
        text-align: center;
    }
`

const FlexContainer2 = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 5vw 5vw 0 6.5vw;

    @media only screen and (max-width: ${smallScreenWidth + 'px'}) {
        flex-direction: column;
        padding-top: 10rem;
        gap: 5rem;
    }
`

const StyledTitle = styled(TextRebrandHeader)`
    font-size: 48px !important;
    line-height: 3rem !important;
`

const SubTitle = styled(TextRebrandHeader)`
    font-size: 30px !important;
    line-height: 2.5rem !important;
`

const StyledDiv = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;

    @media only screen and (max-width: ${smallScreenWidth + 'px'}) {
        padding: 0;
    }
`

const StyledDivTextLeft = styled(StyledDiv)`
    flex: 1;
    text-align: left;
    position: relative;
    align-items: center;

    @media only screen and (max-width: ${smallScreenWidth + 'px'}) {
        text-align: center;
    }
`

const SectionWrapper = styled.div`
    display: flex;
    padding: 3rem 0;
    flex-direction: column;

    @media only screen and (max-width: ${smallScreenWidth + 'px'}) {
        flex-direction: column;
    }
`

const FlexContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2rem;
    padding: 0% 5vw;

    @media only screen and (max-width: ${smallScreenWidth + 'px'}) {
        flex-direction: column-reverse;
    }
`

const CardTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    text-align: left;
    padding-left: 0.5vw;
    @media only screen and (max-width: ${smallScreenWidth + 'px'}) {
        flex: 1;
    }
`

const ArtistBlockOverlay = styled.div`
    position: absolute;
    color: black;
    padding: 0 1vw;
    display: flex;
    align-items: center;
    width: 70%;
    height: 25%;
    background-color: var(--es-background-brand-fog);
    top: 15%;
    right: 5%;
    z-index: 1;

    @media only screen and (max-width: ${smallScreenWidth + 'px'}) {
        padding-left: 5vw;
    }
`

const SearchBlockOverlay = styled.div`
    position: absolute;
    width: 65%;
    height: 45%;
    background-color: var(--es-background-brand-pink);
    top: 40%;
    left: 10%;
    z-index: 1;
    font-size: 1.3vw;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1vw;
    text-align: left;

    @media only screen and (max-width: 1920px) {
        font-size: 2vw;
        padding-left: 3vw;
    }

    @media only screen and (max-width: ${smallScreenWidth + 'px'}) {
        font-size: 5vw;
        padding-left: 3vw;
    }
`

const AnimatedImageContainer = styled.div`
    flex: 1;
    z-index: 2;
    overflow: hidden;
`

const AnimatedWavesContainer = styled.div`
    width: 19vw;
    height: 15vw;
    overflow: hidden;
    position: relative;

    @media only screen and (max-width: 1920px) {
        width: 29vw;
        height: 20vw;
    }

    @media only screen and (max-width: ${smallScreenWidth + 'px'}) {
        height: 55vw;
        width: 90vw;
        margin-top: 6rem;
    }
`

const Italics = styled.span`
    font-style: italic;
`

const TextContainer = styled.div`
    flex: 1;
    text-align: left;
`

const Title = styled(TextRebrandHeader)`
    font-size: 40px !important;
    line-height: 3rem !important;
    flex: 1;
`

const ImageContainer = styled.div`
    display: flex;
    justify-content: right;
    align-items: center;
    flex: 1;
    margin-left: 2rem;
`

const VideoContainer = styled.video`
    width: 112%;
    flex: 1;
    margin-left: -30%;
    margin-right: -30%;
`

const BackgroundVideoContainer = styled.video`
    height: 20vw;
    object-fit: cover;

    @media only screen and (max-width: ${smallScreenWidth + 'px'}) {
        height: 55vw;
        width: 90vw;
    }
`

const IconTextContainer = styled(TextRebrand)`
    display: flex;
    font-weight: bold !important;
    letter-spacing: 0.1rem;
    align-items: center;
    justify-content: left;
    margin-top: 2rem;
    font-size: 0.875rem !important;
`

const IconContainer = styled.span`
    padding-top: 6px;
    margin-right: 0.7rem;
`

const StyledATag = styled.a`
    font-weight: bold;
    text-decoration: none;
    color: black;
    margin-right: 0.5vw;
`

const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 1vw;
    align-items: left;
    justify-content: center;
    text-align: left;
`

const TextRebrandBold = styled(TextRebrand)`
    font-size: 1vw !important;
    font-weight: bold !important;
    line-height: normal !important;

    @media only screen and (max-width: ${smallScreenWidth + 'px'}) {
        font-size: 2vw !important;
    }
`

const TextRebrandArtist = styled(TextRebrand)`
    font-size: 1vw !important;
    line-height: normal !important;

    @media only screen and (max-width: ${smallScreenWidth + 'px'}) {
        font-size: 2vw !important;
    }
`

export const FlowSection: FC = () => {
    return (
        <MainContainer data-cy-landing-page-flow-section>
            <SectionWrapper>
                <StyledDiv>
                    <StyledTitle component={'h1'} color={'black'}>
                        Cutting edge soundtracking features
                    </StyledTitle>
                    <TextRebrand color={'black'}>
                        Offer your users innovative features that transform
                        their soundtracking experience.
                    </TextRebrand>
                </StyledDiv>
                <FlexContainer>
                    <CardTextContainer>
                        <IconTextContainer>
                            <IconContainer>
                                <Icon.Soundmatch
                                    symbolOnly={true}
                                    width="20"
                                    height="20"
                                    fill="black"
                                />
                            </IconContainer>
                            SOUNDMATCH
                        </IconTextContainer>
                        <SubTitle component={'h3'} color={'black'}>
                            Let <Italics>the video</Italics> do the work{' '}
                        </SubTitle>
                        <TextRebrand color={'black'}>
                            Our AI powered discovery tool allows your users to
                            find the perfect soundtrack based on frames within
                            their content. Soundmatch analyzes video frames and
                            provides a list of recommended tracks perfectly
                            suited to the video’s visual scenes.
                        </TextRebrand>
                        <IconTextContainer>
                            <StyledATag href="https://developers.epidemicsound.com/docs/guides#soundmatch-get-music-recommendations">
                                Read more about Soundmatch
                            </StyledATag>
                            <IconContainer>
                                <Icon.ArrowRight
                                    symbolOnly={true}
                                    width="20"
                                    height="20"
                                    fill="black"
                                />
                            </IconContainer>
                        </IconTextContainer>
                    </CardTextContainer>
                    <AnimatedImageContainer>
                        <VideoContainer autoPlay muted loop playsInline>
                            <source
                                src="/assets/img/ES-video-squares.mp4"
                                type="video/mp4"
                            />
                        </VideoContainer>
                    </AnimatedImageContainer>
                </FlexContainer>
                <FlexContainer>
                    <StyledDivTextLeft>
                        <AnimatedWavesContainer>
                            <ArtistBlockOverlay>
                                <IconContainer>
                                    <Icon.Play
                                        symbolOnly={true}
                                        width="1.5vw"
                                        height="1.5vw"
                                        fill="black"
                                    />
                                </IconContainer>
                                <FlexColumn>
                                    <TextRebrandBold>
                                        Minimalism
                                    </TextRebrandBold>
                                    <TextRebrandArtist>
                                        ECOBEL
                                    </TextRebrandArtist>
                                </FlexColumn>
                            </ArtistBlockOverlay>
                            <SearchBlockOverlay>
                                <IconContainer>
                                    <Icon.Search
                                        symbolOnly={true}
                                        width="3vw"
                                        height="3vw"
                                        fill="black"
                                    />
                                </IconContainer>
                                <Italics>Calm ocean vibes</Italics>
                            </SearchBlockOverlay>
                            <BackgroundVideoContainer
                                autoPlay
                                muted
                                loop
                                playsInline
                            >
                                <source
                                    src="/assets/img/ocean.mp4"
                                    type="video/mp4"
                                />
                            </BackgroundVideoContainer>
                        </AnimatedWavesContainer>
                        <CardTextContainer>
                            <IconTextContainer>
                                <IconContainer>
                                    <Icon.Search
                                        symbolOnly={true}
                                        width="20"
                                        height="20"
                                        fill="black"
                                    />
                                </IconContainer>
                                SEMANTIC SEARCH
                            </IconTextContainer>
                            <SubTitle component={'h3'} color={'black'}>
                                Our most powerful search engine yet{' '}
                            </SubTitle>
                            <TextRebrand color={'black'}>
                                Our semantic search allows your users to search
                                in their own words to find the perfect track.
                                Type something specific like “sunset over the
                                ocean” and see for yourself.
                            </TextRebrand>
                            <IconTextContainer>
                                <StyledATag href="https://developers.epidemicsound.com/docs/Endpoints/get-tracks-search">
                                    Read more about Semantic search
                                </StyledATag>
                                <IconContainer>
                                    <Icon.ArrowRight
                                        symbolOnly={true}
                                        width="20"
                                        height="20"
                                        fill="black"
                                    />
                                </IconContainer>
                            </IconTextContainer>
                        </CardTextContainer>
                    </StyledDivTextLeft>
                    <StyledDivTextLeft>
                        <AnimatedWavesContainer>
                            <Image
                                src="/assets/img/waveforms.png"
                                alt="Landing page hero image"
                                layout="fill"
                            />
                        </AnimatedWavesContainer>
                        <CardTextContainer>
                            <IconTextContainer>
                                <IconContainer>
                                    <Icon.Similar
                                        symbolOnly={true}
                                        width="20"
                                        height="20"
                                        fill="black"
                                    />
                                </IconContainer>
                                FIND SIMILAR (EAR)
                            </IconTextContainer>
                            <SubTitle component={'h3'} color={'black'}>
                                Use music to find music{' '}
                            </SubTitle>
                            <TextRebrand color={'black'}>
                                By utilizing EAR (Epidemic Audio Reference),
                                your users can instantly find music similar to a
                                favorite part of a track. If you provide
                                templates, the default sounds can seamlessly be
                                replaced with similar ones using the same
                                technology.
                            </TextRebrand>
                            <IconTextContainer>
                                <StyledATag href="https://developers.epidemicsound.com/docs/guides#templates-with-epidemic-sound-music">
                                    Read more about Find similar
                                </StyledATag>
                                <IconContainer>
                                    <Icon.ArrowRight
                                        symbolOnly={true}
                                        width="20"
                                        height="20"
                                        fill="black"
                                    />
                                </IconContainer>
                            </IconTextContainer>
                        </CardTextContainer>
                    </StyledDivTextLeft>
                    <StyledDivTextLeft>
                        <AnimatedWavesContainer>
                            <Image
                                src="/assets/img/waveform-single.png"
                                layout="fill"
                                alt="Landing page hero image"
                            />
                        </AnimatedWavesContainer>
                        <CardTextContainer>
                            <IconTextContainer>
                                <IconContainer>
                                    <Icon.Flame
                                        symbolOnly={true}
                                        width="20"
                                        height="20"
                                        fill="black"
                                    />
                                </IconContainer>
                                HIGHLIGHTS
                            </IconTextContainer>
                            <SubTitle component={'h3'} color={'black'}>
                                Get the best part of a track instantly{' '}
                            </SubTitle>
                            <TextRebrand color={'black'}>
                                No matter if your users are making a TikTok, a
                                story, or a snap - our Highlights feature will
                                recommend the most relevant part of a track for
                                their short form content.
                            </TextRebrand>
                            <IconTextContainer>
                                <StyledATag href="https://developers.epidemicsound.com/docs/Endpoints/get-highlights-details">
                                    Read more about Highlights
                                </StyledATag>
                                <IconContainer>
                                    <Icon.ArrowRight
                                        symbolOnly={true}
                                        width="20"
                                        height="20"
                                        fill="black"
                                    />
                                </IconContainer>
                            </IconTextContainer>
                        </CardTextContainer>
                    </StyledDivTextLeft>
                </FlexContainer>
                <FlexContainer2>
                    <TextContainer>
                        <Title component={'h3'}>
                            Desktop, web & mobile apps
                        </Title>
                        <TextRebrand>
                            The majority of creators produce and publish content
                            across a spectrum of tools, apps, and platforms.
                            Keep your users focused in their workflow with the
                            right assets and soundtracking tools directly inside
                            your app with the Epidemic Sound Partner API. Our
                            API works cross-platform, so no matter if your app
                            is on mobile, desktop, or web - or a combination of
                            them - you’ll be sure your users stay in their flow.
                        </TextRebrand>
                    </TextContainer>
                    <ImageContainer>
                        <Image
                            src="/assets/img/collage.png"
                            alt="Landing page hero image"
                            height={600}
                            width={550}
                        />
                    </ImageContainer>
                </FlexContainer2>
            </SectionWrapper>
        </MainContainer>
    )
}
