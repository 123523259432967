import { FC, useEffect, useMemo, useState } from 'react'
import { Typography } from '@epidemicsound/design-system'
import styled from 'styled-components'
import { useActiveTrack, useLoading, usePlayerQueue } from 'hooks/hooks'
import { PlaybackStatus } from 'utils'
import { PublicCollectionResponse } from 'api/responses'
import { PlayIcon } from 'components/icon/PlayIcon'
import Image from 'next/image'

const { Text } = Typography

const CollectionCover = styled.div`
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 208px;
    height: 208px;

    i {
        margin-left: 0;
    }

    svg {
        zoom: 3;
    }
`

const TextContainer = styled.div`
    padding: 1rem;
    max-width: 208px;
    text-align: left;
    background-color: #121418;
`

interface CoverProps {
    collection: PublicCollectionResponse
}

export const Cover: FC<CoverProps> = ({ collection }) => {
    const schema = process.env.NEXT_PUBLIC_CONTENTFUL_IMAGE_SCHEMA
    const { setQueue, queue, nextTrack } = usePlayerQueue()
    const { activeTrack, pauseTrack, stopTrack, playTrack } = useActiveTrack()
    const [status, setStatus] = useState<PlaybackStatus>('pause')
    const { isLoading } = useLoading()
    const [isPlayNewCollection, setPlayNewCollection] = useState(false)
    const tracksToPlay = useMemo(
        () =>
            collection.urls.map((url) => {
                return {
                    id: '',
                    title: '',
                    length: 100,
                    bpm: 0,
                    images: {
                        L: '',
                        M: '',
                        S: '',
                        XS: '',
                        default: '',
                    },
                    genres: [],
                    mainArtists: [],
                    moods: [],
                    url: url,
                    waveformUrl: '',
                }
            }),
        []
    )

    const playCollection = async (collection: PublicCollectionResponse) => {
        if (!queue) {
            playTrack({ ...tracksToPlay[0], queueName: collection.title })
            setPlayNewCollection(true)
        } else {
            if (queue.name !== collection.title) {
                setPlayNewCollection(true)
            } else if (status === 'pause' && activeTrack) {
                // Toggling from pause -> play
                nextTrack({ ...activeTrack })
                setStatus('play')
            } else if (status === 'play') {
                // Toggling from play -> pause
                pauseTrack()
                setStatus('pause')
            }
        }
    }

    useEffect(() => {
        if (!activeTrack && queue && queue.tracks) {
            nextTrack()
            if (collection.title === queue.name) {
                setStatus('play')
            } else {
                setStatus('pause')
            }
        }
    }, [queue?.name])

    useEffect(() => {
        if (isPlayNewCollection === false) return

        if (!queue) {
            setQueue({ name: collection.title, tracks: tracksToPlay })

            const timeout = setTimeout(() => {
                setStatus('play')
                nextTrack()
                setPlayNewCollection(false)
            }, 250)
            return () => clearTimeout(timeout)
        } else {
            // Stop and play new collection!
            stopTrack()

            const timeout = setTimeout(() => {
                setQueue({ name: collection.title, tracks: tracksToPlay })
                setPlayNewCollection(false)
            }, 500)
            return () => clearTimeout(timeout)
        }
    }, [isPlayNewCollection])

    return (
        <div>
            <CollectionCover onClick={() => playCollection(collection)}>
                <PlayIcon isLoading={isLoading} status={status} />
                <Image
                    src={schema + ':' + collection.imageUrl}
                    alt={collection.title}
                    layout="fill"
                />
            </CollectionCover>
            <TextContainer>
                <Text component="div" size={Text.SIZES.S}>
                    {collection.title}
                </Text>
            </TextContainer>
        </div>
    )
}
