import { FC } from 'react'
import styled from 'styled-components'
import { constants, Typography, Link } from '@epidemicsound/design-system'
import { EpidemicLogo } from '../../logo/EpidemicLogo'
import { smallScreenWidth } from 'hooks/hooks'
import { Icon } from '@epidemicsound/design-system'

const { Text } = Typography

const StyledPageFooterLinkSection = styled.footer`
    display: flex;
    padding: 5rem;
    height: 336px;
    @media only screen and (max-width: ${smallScreenWidth + 'px'}) {
        flex-direction: column-reverse;
        padding: 1rem;
        height: 500px;
    }
`

const StyledPageFooterLogoContainer = styled.div`
    display: flex;
    flex: 1;

    @media only screen and (max-width: ${smallScreenWidth + 'px'}) {
        margin-bottom: 4rem;
        order: -1;
        justify-content: flex-start;
    }
`

const StyledPageFooterLinkRightSection = styled.section`
    display: flex;
    flex-direction: column;
    height: 11.375rem;
    padding-left: 3.5rem;
    flex: 2;
    gap: 100px;

    @media only screen and (max-width: ${smallScreenWidth + 'px'}) {
        padding-left: 0;
        padding-top: 1.25rem;
        gap: 1.25rem;
    }
`

const StyledPageFooterLinkRight = styled.div`
    margin-right: 1.5rem;
    margin-bottom: 0.5rem;
`

const StyledPageFooterLinkLeftSection = styled.div`
    display: flex;
    flex-direction: column;
    flex: 2;

    @media only screen and (max-width: ${smallScreenWidth + 'px'}) {
        padding-bottom: 1.25rem;
        flex: 0;
    }
`

const StyledPageFooterLinks = styled.div`
    margin-right: 2vw;
    min-width: 9.375rem;

    @media only screen and (max-width: ${smallScreenWidth + 'px'}) {
        min-width: 7.5rem;
    }
`

const StyledPageFooterLink = styled.div`
    margin-bottom: 0.5rem;
    color: black !important;
`

const Diver = styled.div`
    display: flex;
    padding-top: 0.5rem;
`

const LinkIconsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @media only screen and (max-width: ${smallScreenWidth + 'px'}) {
        justify-content: center;
    }
`

interface PageFooterLink {
    name: string
    href: string
    icon?: JSX.Element
}

export const PageFooter: FC = () => {
    const links = [
        [
            {
                name: 'Music',
                href: 'https://www.epidemicsound.com/music/featured/',
            },
            {
                name: 'Sound Effects',
                href: 'https://www.epidemicsound.com/sound-effects/',
            },
            {
                name: 'Downloads',
                href: 'https://www.epidemicsound.com/downloads/',
            },
            {
                name: 'For Artists',
                href: 'https://www.epidemicsound.com/campaign/artist/',
            },
            {
                name: 'For Developers',
                href: 'https://developers.epidemicsound.com/',
            },
            {
                name: 'In-Store Music',
                href: 'https://www.epidemicsound.com/instore/instore-music/',
            },
            {
                name: 'Blog',
                href: 'https://www.epidemicsound.com/blog/',
            },
        ],
        [
            {
                name: 'Mobile App',
                href: 'https://www.epidemicsound.com/app/',
            },
            {
                name: 'Help',
                href: 'https://support.epidemicsound.com/',
            },
            {
                name: 'About us',
                href: 'https://www.epidemicsound.com/about-us/',
            },
            {
                name: 'Pricing',
                href: 'https://www.epidemicsound.com/pricing/',
            },
            {
                name: 'Enterprise',
                href: 'https://www.epidemicsound.com/enterprise/',
            },
            {
                name: 'Press',
                href: 'https://www.epidemicsound.com/press',
            },
            {
                name: 'Careers',
                href: 'https://epidemic-sound.teamtailor.com/',
            },
        ],
        [
            {
                name: 'API Terms of Service',
                href: '/policy/terms/conditions/',
            },
            {
                name: 'Acceptable Use Policy',
                href: '/policy/acceptable-use-policy',
            },
            {
                name: 'Privacy',
                href: 'https://www.epidemicsound.com/policy/v2/privacy-policy',
            },
            {
                name: 'General',
                href: 'https://www.epidemicsound.com/policy/general-terms-and-conditions',
            },
            {
                name: 'Cookie',
                href: 'https://www.epidemicsound.com/policy/cookie-policy/',
            },
        ],
    ] as PageFooterLink[][]

    const rightLinks = [
        {
            name: 'Spotify',
            href: 'https://open.spotify.com/user/epidemicsound?si=wJ4g9W5mQaWkoECTLWo0yw&nd=1',
            icon: <Icon.Spotify fill={'black'} />,
        },
        {
            name: 'Youtube',
            href: 'https://www.youtube.com/channel/UC_sOjEnngNB2y_AEaDd2cSA',
            icon: <Icon.YoutubeMusic fill={'black'} />,
        },
        {
            name: 'Instagram',
            href: 'https://www.instagram.com/epidemicsound/',
            icon: <Icon.Instagram fill={'black'} />,
        },
        {
            name: 'Facebook',
            href: 'https://www.facebook.com/EpidemicSound/',
            icon: <Icon.Facebook fill={'black'} />,
        },
        {
            name: 'LinkedIn',
            href: 'https://www.linkedin.com/company/epidemic-sound',
            icon: <Icon.LinkedIn fill={'black'} />,
        },
    ] as PageFooterLink[]

    return (
        <section data-cy-landing-page-footer>
            <StyledPageFooterLinkSection>
                <StyledPageFooterLinkLeftSection>
                    <StyledPageFooterLogoContainer>
                        <EpidemicLogo />
                    </StyledPageFooterLogoContainer>
                    <Text color={constants.COLORS.GRAY_2}>
                        Copyright © Epidemic Sound
                    </Text>
                </StyledPageFooterLinkLeftSection>
                <StyledPageFooterLinkRightSection>
                    <Diver>
                        {links.map((moreLinks, moreLinksIndex) => {
                            return (
                                <StyledPageFooterLinks
                                    key={'links-' + moreLinksIndex}
                                >
                                    {moreLinks.map((link, linkIndex) => {
                                        return (
                                            <StyledPageFooterLink
                                                key={'link-' + linkIndex}
                                            >
                                                <Link
                                                    href={link.href}
                                                    target={'_blank'}
                                                    color={'black'}
                                                    isUnderlinedOnHover
                                                >
                                                    {link.name}
                                                </Link>
                                            </StyledPageFooterLink>
                                        )
                                    })}
                                </StyledPageFooterLinks>
                            )
                        })}
                    </Diver>
                    <LinkIconsContainer>
                        {rightLinks.map((link, bottomLinkIndex) => {
                            return (
                                <StyledPageFooterLinkRight
                                    key={'bottom-link-' + bottomLinkIndex}
                                >
                                    <Link
                                        href={link.href}
                                        target={'_blank'}
                                        color={'black'}
                                        isUnderlinedOnHover
                                    >
                                        {link.icon}
                                    </Link>
                                </StyledPageFooterLinkRight>
                            )
                        })}
                    </LinkIconsContainer>
                </StyledPageFooterLinkRightSection>
            </StyledPageFooterLinkSection>
        </section>
    )
}
