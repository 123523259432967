import styled from 'styled-components'
import { smallScreenWidth } from 'hooks/hooks'

export const SectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: center;

    @media only screen and (max-width: ${smallScreenWidth + 'px'}) {
        text-align: left;
        padding-top: 0;
    }
`
