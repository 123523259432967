import { v4 as uuidv4 } from 'uuid'

import { getCookie, setCookie } from './cookie'

const CONSTANTS = {
    // Client session specific constants
    SESSION_ID_KEY: 'session_id',
    SESSION_COOKIE_NAME: 'client_session_id',

    // Snowplow specific constants
    DEVELOPERS_PORTAL_CTX: 'PAPI_DEVELOPERS_PORTAL',
    DEVELOPERS_PORTAL_TRACKER_NAME: 'sp-developers-portal',

    SNOWPLOW_TRACKER_ID: 'sp-developers-portal',
    SNOWPLOW_COLLECTER_URL:
        process.env.NEXT_PUBLIC_SNOWPLOW_HOST || 'localhost:3000',
    SNOWPLOW_HOST: process.env.NEXT_PUBLIC_SNOWPLOW_HOST || 'localhost:3000',
    SNOWPLOW_POST_PATH:
        process.env.NEXT_PUBLIC_SNOWPLOW_POST_PATH || '/snowplow',
    SNOWPLOW_APP_ID: 'partner-content-ui',
}

// Entities (context) in snowplow
const ENTITIES = {
    CONTEXT: 'iglu:com.epidemicsound/context/jsonschema/1-0-38',
    WEB_SESSION: 'iglu:com.epidemicsound/web_session/jsonschema/2-0-0',
    EVENT_UUID: 'iglu:com.epidemicsound/event_uuid/jsonschema/1-0-0',
}

const SCHEMAS = {
    PAGE_VIEW: 'iglu:com.epidemicsound/pageview/jsonschema/4-0-2',
    CTA_CLICKED: 'iglu:com.epidemicsound/cta_clicked/jsonschema/3-0-0',
}

function pageViewSchemaData(landingPage?: string) {
    return {
        schema: SCHEMAS.PAGE_VIEW,
        data: {
            landing_page: landingPage,
        },
    }
}

function addContextEntity(context?: string) {
    return {
        schema: ENTITIES.CONTEXT,
        data: {
            context: context ? context : CONSTANTS.DEVELOPERS_PORTAL_CTX,
        },
    }
}

function addWebSessionEntity() {
    return {
        schema: ENTITIES.WEB_SESSION,
        data: {
            client_session_id: getClientSessionId(),
        },
    }
}

function addEventUUIDEntity() {
    const event_uuid = {
        schema: ENTITIES.EVENT_UUID,
        data: {
            event_uuid: uuidv4(),
        },
    }
    return event_uuid
}

function getSnowplowDomainUid() {
    const cookieName = '_sp_'
    const matcher = new RegExp(`${cookieName}id\\.[a-f0-9]+=([^;]+);?`)
    const match = document.cookie.match(matcher)
    if (match && match[1]) {
        const split = match[1].split('.')
        return {
            domain_userid: split[0],
            domain_sessionidx: split[2],
            domain_sessionid: split[5],
        }
    }
    return false
}

function getClientSessionId() {
    const spTrackingInfo = getSnowplowDomainUid()
    const host = window.location.hostname
    const domainParts = host.split('.')
    domainParts.shift()
    const domain = domainParts.join('.')

    if (!document.cookie.includes(CONSTANTS.SESSION_COOKIE_NAME)) {
        // If no session_id and no cookies, use snowplow session_id
        if (spTrackingInfo && spTrackingInfo.domain_sessionid) {
            setCookie(
                CONSTANTS.SESSION_COOKIE_NAME,
                spTrackingInfo.domain_sessionid,
                730, // default cookieLifetime
                domain
            )
        } else {
            const generatedSessionUUID = uuidv4()
            setCookie(
                CONSTANTS.SESSION_COOKIE_NAME,
                generatedSessionUUID,
                730,
                domain
            )
        }
    }
    return getCookie(CONSTANTS.SESSION_COOKIE_NAME)
}

export {
    CONSTANTS as const,
    SCHEMAS as schemas,
    addEventUUIDEntity,
    addContextEntity,
    addWebSessionEntity,
    pageViewSchemaData,
}
